@use 'setting' as *;

/* ======================================================================
 p-top
====================================================================== */
// l_main_visual_top
.u_content_size {
  padding: 0 $sp_side_padding;
  margin: 0 auto;
  @include pc_tab {
    width: $content_width;
  }
}

.font_oswald {
  font-family: "oswald", sans-serif;
  font-style: normal;
}
img {
  width: auto;
  height: auto;
  @include sp {
    // width: 100%;
  }
}
 
.u_txt_16-1 {
  font-size: 16px;
  line-height: calc(30 / 16);
}
.u_txt_13-1 {
  font-size: 13px;
  line-height: calc(30 / 16);
}

.u_pc_br {
  display: block;
  @include sp {
    display: none;
  }
}
.u_sp_br {
  display: none;
  @include sp {
    display: block;
  }
}

.ttl01 {
  position: relative;
  margin: 0 0 40px;
  &:after {
    content: "";
    display: block;
    margin: 20px auto 0;
    width: 65px;
    height: 5px;
    background: linear-gradient(94deg, #0777cc 0%, #6aade0 100%);
    @include sp {
      width: 55px;
      margin: 15px auto 0;
    }
  }
  @include sp {
    margin: 40px 0 20px;
  }
}


/* ===========================================
* キービジュアル
* ======================================== */
.page_ttl {
  position: absolute;
  top: 64px;
  left: 156px;
  @include sp {
    position: absolute;
    width: 100%;
    top: 57px;
    left: 0;
    filter: drop-shadow(1px 2px 2px #04345899);
  }
  .img {
    width: 100%;
  }
}

.company_logo {
  position: absolute;
  top: 25px;
  left: -30px;
  @include sp {
    top: 15px;
    left: 0;
    width: 70px;
  }
  .img {
    @include sp {
      width: 100%;
    }
  }
}

.kv {
  &_wrapper {
    position: relative;
    width: 100%;
    height: 643px;
    background: url(#{$img_dir}/pc_kv_bg.png);
    background-size: cover;
    @include sp {
      width: 100%;
      height: 550px;
      background: url(#{$img_dir}/sp_kv_bg.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
    }
    &::before {
      position: absolute;
      top: 83%;
      left: 0;
      width: 100%;
      height: 111px;
      content: "";
      background: url(#{$img_dir}/pc_kv_shape.svg);
      background-size: 100% 100%;
      background-repeat: no-repeat;
      z-index: 5;
      @include sp {
        top: 440px;
        background: url(#{$img_dir}/sp_kv_shape.svg);
        background-size: 100% 100%;
        background-repeat: no-repeat;
        
      }
    }
    .bnr_contact_btn_wrapper {
      position: absolute;
      top: 576px;
      left: 49%;
      transform: translate(-50%, -50%);
      z-index: 10;
      .bubble {
        position: absolute;
        top: -29px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 10;
      }
    }
    
  }
  &_inner {
    position: relative;
    width: 1086px;
    height: 100%;
    margin: 0 auto;
    @include sp {
      margin: 0 auto;
      min-width: inherit;
      width: 345px;
    }
  }
  &_cost_saving_wrapper {
    position: absolute;
    top: 368px;
    left: 0;
    width: 100vw;
    height: 113px;
    z-index: 0;
    background: transparent;
    backdrop-filter: blur(10px) brightness(1.07);
    --webkit-backdrop-filter: blur(8px);
    background-color: rgba(255, 255, 255, 0.2);
    @include sp {
      top: 240px;
      left: 0;
      width: 100%;
      height: 96px;
    }
  }
  &_lead {
    @include sp {
      position: absolute;
      top: -16px;
      left: -4px;
      width: 220px;
    }
    .img {
      @include sp {
        width: 100%;
      }
    }
  }
  &_cost_list{
    display: flex;
    padding: 19px 0 22px;
    justify-content: space-between;
    position: absolute;
    top: 369px;
    left: 0;
    width: 825px;
    height: 113px;
    @include sp {
      display: none;
    }
    &:after {
      @include pc_tab {
        content: url(#{$img_dir}/pc_kv_lead.svg);
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translate(-50%, 0);
      }
    }
  }
  &_cost_item {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    width: calc(100% / 3);
    &::before {
      position: absolute;
      top: 50%;
      right: 0;
      width: 2px;
      height: 49px;
      background: #C0DDF4;
      content: "";
      transform: translate(0, -50%);
      @include sp {
        display: none;
      }
    }
    &:last-of-type {
      &::before {
        background: none;
      }
    }
    @include sp {
      width: 230px;
    }
  }
  &_cost_ttl {
    @include sp {
      margin: 18px auto 0;
    }
    &.ttl_laundry {
      @include sp {
        width: 131px;
      }
    }
    &.ttl_car {
      @include sp {
        width: 87px;
      }
    }
    &.ttl_iron {
      @include sp {
        width: 54px;
      }
    }
  }
  &_cost_text {
    display: flex;
    justify-content: center;
    width: 100%;
    font-size: 25px;
    color: $secondary_color;
    font-weight: 700;
    @include sp {
      justify-content: inherit;
      margin: 2px 0 0;
      font-size: 20px;
    }
    .font_oswald{
      font-size: 40px;
      font-weight: 700;
      letter-spacing: 1px;
      @include sp {
        letter-spacing: 0;
        font-size: 35px;
      }
    }
  }
  &_cost_cost {
    margin: -10px 7px 0px;
    @include sp {
      margin: 0 4px 0px;
      white-space: nowrap;
      
    }
  }
  &_cost_year {
    @include sp {
      margin: 4px 0 0;
      width: 18px;
    }
  }
  &_cost_reduction {
    margin-top: 5px;
    @include sp {
      margin-top: 12px;
      width: 53px;
    }
  }
  &_breaker {
    position: absolute;
    top: 64px;
    right: -16px;
    z-index: 1;
    @include sp {
      top: 215px;
      right: -13px;
    }
  }
  &_breaker_img {
    @include sp {
      width: 138px;
    }
    .img {
      width: 263px;
      @include sp {
        width: 100%;
      }
    }
  }
  &_first_cost_img {
    width: fit-content;
    position: absolute;
    top: -24px;
    right: -99px;
    @include sp {
      top: 132px;
      right: 252px;
      width: 104px;
    }
    .img {
      @include sp {
        width: 100%;
      }
    }
  }
  &_first_cost_text {
    
  }
  &_achievement_img {
    width: fit-content;
    position: absolute;
    top: 125px;
    right: -99px;
    @include sp {
      top: 132px;
      right: 145px;
      width: 104px;
    }
    .img {
      @include sp {
        width: 100%;
      }
    }
  }
}

.bnr_contact_btn_wrapper-01 {
  position: relative;
  margin: 0 auto;
  .bubble {
    position: absolute;
    top: 0;
    left: 50%;
    width: 192px;
    transform: translate(-50%,-50%);
    transition: al .3s;
    z-index: 10;
  }
}

.bnr_contact_btn  {
  .bubble {
    @include sp {
      position: absolute;
      top: -29px;
      left: 50%;
      transform: translate(-50%, 0);
      z-index: 10;
    }
  }
}
// キービジュアル_スライダー
.u_sp {
  &.kv_slider {
    position: absolute;
    top: 240px;
    left: 0px;
  }
}


.cost_slider {
  &_list {
    width: 230px;
    height: 96px;
    // outline: 1px solid red;
  }
  &_item {
    width: 100%;
    .img {
      width: 100%;
    }
  }
}
.kv_slider {
  .slider {
    .slick-slide {
      &.kv_cost_item {
        width: 230px;
        height: 96px;
      }
    }
  }
  .slider .slick-slide img.img {
    height: auto;
    object-fit: none;
  }

  /* =============================================== */
  /* スライドの設定
  /* =============================================== */

  .slider .slick-slide {/* スライド単体のデザイン */
    position: relative;
    margin: 0 40px;
    width: 600px;
    height: 400px;
    overflow: hidden;
  }
  .slider .slick-slide img {/* スライドの画像の基本設定 */
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  /* =============================================== */
  /* prev,nextボタンの設定
  /* =============================================== */
  .slider .slick-arrow {/* ボタン全体の見た目 */
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    border: none;
    outline: none;
    font-size: 0;
    top: 180px;
    z-index: 1;
    filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  }
  .slider .slick-arrow::before {/* ボタンの中のarroowの見た目 */
    content: '';
    display: block;
    width: 12px;
    height: 12px;
    border: 1px solid #09000d;
    border-width: 3px 3px 0 0;
    position: absolute;
    top: 24px;
    transform: rotate(45deg);
  }
  .slider .slick-next {/* nexxtボタン全体の位置調整 */
    right: 0;
  }
  .slider .slick-prev {/* prevボタン全体の位置調整 */
    left: 0;
  }

  .slider .slick-next::before {/* nextボタン内のarrowの位置調整 */
    left: 20px;
  }
  .slider .slick-prev::before {/* prevボタン内のarrowの位置調整 */
    border-width: 0 0 3px 3px;
    right: 22px;
  }
  /* =============================================== */
  /* ドットの設定
  /* =============================================== */
  .dots {
    @include sp {
      margin: -20px 88px 0;
    }
  }

  .dots .slick-dots {
    text-align: center;
  }
  .dots .slick-dots li {
    display: inline-block;
  }
  .dots .slick-dots button {/*ドットのデザイン*/
    display: block;
    margin-right: 5px;
    width: 7px;
    height: 7px;
    font-size: 0;
    padding: 0;
    border: 0;
    outline: none;
    cursor: pointer;
    background: #0069B8;
    border-radius: 50%;
  }
  .dots:last-of-type {
    .slick-dots button {
      margin-right: none;
    }
  }

  .dots .slick-dots .slick-active button {/*ドットが選択された際の色変化*/
    background: #fff;
  }
  .slick-dots {/*デフォルトだとabsoluteのため解除している*/
    position: inherit;
  }
}


// 電気代でこんなお悩みございませんか？
.trouble {
  position: relative;
  background: url(#{$img_dir}/pc_wavy_line.svg) no-repeat;
  background-position: center 160px;
  background-size: contain;
  margin: 45px 0 0;
  @include sp {
    background: url(#{$img_dir}/sp_wavy_line.svg) no-repeat;
    background-position: -70px 100px;
    background-size: auto;
    z-index: 5;
    margin: -40px 0 0;
  }
  &_list{
    display: flex;
    justify-content: space-between;
    margin: 40px auto 36px;
    @include sp {
      flex-direction: column;
      gap: 40px;
    }
  }
  &_item {
    display: flex;
    flex-direction: column;
    width: 306px;
    @include sp {
      flex-direction: row;
      align-items: center;
      width: 100%;
    }
    &:nth-of-type(even) {
      @include sp {
        flex-direction: row-reverse;
      }
    }
  }
  &_img_wrapper {
    margin: 0 auto 20px;
    width: 220px;
    @include sp {
      margin: 0 10px 0;
      width: 120px;
    }
  }
  &_img {
    width: 220px;
    @include sp {
      width: 120px;
    }
  }
  &_text{
    font-size: 18px;
    line-height: calc(32 / 18);
    @include sp {
      font-size: 16px;
    }
  }
}

.sec {
  &_under_arrow {
    margin: 0 auto 50px;
    width: 40px;
    height: 20px;
    @include sp {
      margin: 0 auto 20px;
      width: 32px;
      height: 37px;
    }
  }
  &_text {
    margin: 0 auto 10px;
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: calc(32 / 18);
    @include sp {
      font-size: 18px;
      margin: 0 0 5px;
    }
  }
}

.solution {
  &_wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 auto;
    width: 100%;
  }
  &_ttl{
    position: relative;
    display: inline-block;
    background: #fff;
    width: fit-content;
    margin: 0 auto;
    padding: 0 0 0 15px;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    z-index: 1;
    @include sp {
      font-size: 18px;
      padding: 0 2px 0 6px;
    }
  }
  &_list{
    border: 3px solid $primary_color;
    border-radius: 3px;
    padding: 50px 50px 35px;
    position: relative;
    top: -28px;
    @include sp {
      margin: 15px auto 0;
      width: 100%;
      padding: 25px 15px 0;
    }
  }
  &_item{
    padding: 15px 0;
    @include sp {
      padding: 10px 0;
    }
    border-top: 2px solid #e5e5e5;
    &:first-of-type {
      padding: 0 0 15px;
      border: 0;
      @include sp {
        padding: 0 0 10px;
      }
    }
  }
  &_text{
    position: relative;
    padding: 0 0 0 36px;
    font-size: 18px;
    font-weight: 700;
    line-height: calc(32 / 18);
    @include sp {
      padding: 0 0 0 25px;
      font-size: 16px;
      
    }
    &::before {
      position: absolute;
      top: 4px;
      left: 0;
      width: 26px;
      height: 26px;
      content: "";
      background: url(#{$img_dir}/pc_icon_check_Box.png) ;
      @include sp {
        width: 20px;
        height: 20px;
        background: url(#{$img_dir}/sp_icon_check_Box.png) ;
      }
    }
  }
}

.excl {
  letter-spacing: -6px;
}

.yellow_highlight {
  background:linear-gradient(transparent 60%, #FFF36A 60%);
  &.yellow_highlight-price{
    background-position: left;
    background-repeat: no-repeat;
    background-size: 97%;
  }
  &.yellow_highlight-suggestion{
    background-position: left;
    background-repeat: no-repeat;
    background-size: 96%;
  }
}

.black_text {
  background: #fff;
  letter-spacing: -2px;
  @include sp {
    margin: 0 -2px 0 0;
  }
}

.blue-text{
  display: inline-block;
  padding: 4px 6px 6px;
  background: $primary_color;
  font-size: 38px;
  color: #fff;
  border-radius: 2px;
  margin: 0 4px 0 0;
  @include sp {
    font-size: 22px;
    margin: 0;
    padding: 2px 4px 4px;
  }
}

.red_text {
  position: relative;
  font-size: 38px;
  color: #EB5100;
  background: #fff;
  z-index: 1;
  @include sp {
    font-size: 22px;
  }
  &.__solution {
    letter-spacing: -0.09em;;
  }
  &.__problem {
    font-size: 34px;
  }
}

.trouble-container {
  @include pc_tab {
    max-width: 1086px;
    width: 100%;
    margin: 0 auto;
  }
  @include sp {
    margin: 0 auto;
    padding: 0 15px;
  }
  .ttl01 {
    @include sp {
      margin: 0 0 20px;
    }
  }
}


.breaker_explain {
  &_wrapper {
    margin: 0 auto;
    padding: 40px;
    width: 966px;
    background: #fff;
    border-radius: 5px;
    @include sp {
      padding: 30px 20px 0;
      width: calc(100% - 30px);
    }
  }
  &_wrapper_inner{
    display: flex;
    justify-content: space-between;
    @include sp {
      flex-direction: column;
    }
  }
  &_contents{
    width: 736px;
    @include sp {
      width: 100%;
    }
  }
  &_ttl{
    position: relative;
    margin: 0 0 30px;
    padding: 0 0 0 10px;
    font-size: 24px;
    font-weight: 700;
    @include sp {
      margin: 0 0 20px;
      font-size: 20px;
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 28px;
      content: "";
      background: $primary_color;
      transform: translate(-50%,0);
      @include sp {
        height: 24px;
      }
    }
  }
  &_txt{
    font-size: 16px;
    line-height: calc(30 / 16);
    @include sp {
      font-size: 16px;
    }
    &:last-of-type {
      margin: 20px 0 0;
      @include sp { 
        margin: 15px 0 20px;
      }
    }
  }
  &_img {
    @include sp {
      margin: 0 auto;
      width: 140px;
    }
    .img {
      @include sp {
        width: 140px;
      }
      @include pc_tab {
        width: 101px;
        height: 225px;
      }
    }
  }
}


.problem {
  margin: 50px 0 0;
  padding: 80px 0 0;
  background: url(#{$img_dir}/bg-sec.svg) $blue_1 top left / contain;
  @include sp {
    margin: 10px 0 0;
    padding: 40px 0 30px;
  }
  &_lead{
    margin: 40px auto 40px;
    font-size: 16px;
    line-height: calc(28 / 16);
    @include sp {
      margin: 0 auto 20px;
      padding: 0 15px;
      
    }
  }
  &_contents{
    background: #fff;
    margin: 0 0 60px;
    padding: 40px 92px;
    border-radius: 5px;
    @include sp {
      margin: 0 auto 30px;
      padding: 20px 10px;
      width: calc(100% - 30px);
    }
  }
  &_img{
    margin: 0 auto 21px;
    width: fit-content;
    @include sp {
      margin: 0 auto 13px;
    }
    .img {
      width: 718px;
      @include sp {
        width: 308px;
      }
    }
  }
  &_txt {
    margin: 0 auto 25px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    line-height: calc(30 / 18);
    @include sp {
      margin: 0 auto 20px;
      font-size: 18px;
    }
    &_red {
      font-size: 30px;
      color: #EB5100;
      line-height: calc(32 / 22);
      @include sp {
        font-size: 22px;
      }
    }
  }
  &_txt_02 {
    margin: 0 auto;
    width: fit-content;
    border-bottom: 2px solid #C0DDF4;
    padding: 0 0 3px;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    line-height: calc(46 / 28);
    @include sp {
      padding: 0 0 1px;
      font-size: 18px;
      margin: 0 auto 7px;
    }
    .red_text {
      background: inherit;
    }
    &:last-of-type {
      margin: 15px auto 50px;
      @include sp {
        margin: 0 auto 35px;
      }
    }
  }
  &_txt_03 {
    position: relative;
    margin: 0 auto;
    padding: 0px 4px 4px 10px;
    width: fit-content;
    background: $primary_color;
    color: #fff;
    text-align: center;
    font-size: 36px;
    font-weight: 700;
    line-height: calc(52 / 36);
    border-radius: 2px;
    @include sp {
      font-size: 20px;
      padding: 4px 8px 4px 10px;
    }
    &::before {
      position: absolute;
      top: -60px;
      left: -74px;
      width: 93px;
      height: 93px;
      content: "";
      background-image: url(#{$img_dir}/pc_bubble_now.png);
      background-size: cover;
      @include sp {
        display: none;
      }
    }
    &::after {
      position: absolute;
      top: -96px;
      right: -190px;
      width: 172px;
      height: 231px;
      content: "";
      background-image: url(#{$img_dir}/pc_lady_ver02.png);
      background-size: cover;
      @include sp {
        display: none;
      }
    }
    &:first-of-type {
      @include sp {
        margin: 0 auto 10px;
      }
    }
    &:last-of-type {
      @include sp {
        margin: 0;
      }
    }
  }
  &_txt_03_wrapper{
    @include sp {
      position: relative;
      margin: 0 auto;
      width: fit-content;
    }
    &::before {
      @include sp {
        position: absolute;
        top: -16px;
        left: -34px;
        width: 46px;
        height: 45px;
        content: "";
        background-size: 46px 45px;
        background-image: url(#{$img_dir}/sp_bubble_now.png);
        z-index: 10;
      }
    }
    &::after {
      @include sp {
        position: absolute;
        top: 4px;
        right: -48px;
        width: 80px;
        height: 108px;
        content: "";
        background-size: 80px 108px;
        background-image: url(#{$img_dir}/sp_lady_ver02.png);
      }
    }
  }
}

.chart_annotation {
  margin: 0 auto 50px;
  text-align: right;
  font-size: 13px;
  @include sp {
    margin: 0 auto 35px;
    font-size: 12px;
  }
}

.plan {
  &_wrapper {
    margin: 0 auto;
    width: 623px;
    @include sp {
      width: 100%;
    }
  }
  &_ttl{
    position: relative;
    margin: 0 auto 25px;
    padding: 0 10px;
    width: fit-content;
    text-align: center;
    font-size: 28px;
    font-weight: 700;
    @include sp {
      font-size: 20px;
      margin: 0 auto 20px;
      line-height: calc(30 / 20);
      letter-spacing: .02em;
    }
    &:before,
    &:after {
      position: absolute;
      top: calc(50% + 2px);
      width: 20px;
      height: 3px;
      content: "";
      background: $primary_color;
      transform: translate(0,-50%);
      @include sp {
        top: 30px;
        transform: none;
      }
    }
    &:before {
      left: -20px;
    }
    &:after {
      right: -20px;
    }
  }
  &_list {
    display: flex;
    width: 566px;
    justify-content: space-between;
    margin: 0 auto 20px;
    @include sp {
      width: auto;
      margin: 0 auto 10px;
    }
  }
  &_item {
    padding: 5px 20px 7px;
    width: 263px;
    border-radius: 5px;
    background: $primary_color;
    @include sp {
      padding: 15px 12px 8px;
      width: calc(50% - 7px)
    }
  }
  &_annotation{
    position: relative;
    padding: 0 0 0 13px;
    text-align: center;
    font-size: 13px;
    @include sp {
      text-align: left;
      font-size: 12px;
      line-height: calc(20 / 12);
    }
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      content: "※";
    }

  }
}
.wat {
  position: relative;
  margin: 0 auto 10px;
  padding: 0 0 13px;
  text-align: center;
  color: #fff;
  font-size: 16px;
  @include sp {
    margin: 0 auto;
    padding: 0px 0 6px
    ;
  }
  &_num {
    font-size: 35px;
    color: $secondary_color;
    letter-spacing: 1px;
    @include sp {
      font-size: 30px;
    }
  }
  &_unit {
    font-size: 25px;
    color: $secondary_color;
    @include sp {
      font-size: 22px;
    }
  }
  &::before {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 2px;
    content: "";
    background: #fff;
  }
}
.price {
  text-align: center;
  font-size: 30px;
  color: $secondary_color;
  @include sp {
    font-size: 22px;
    white-space: nowrap;
  }
  &_num {
    font-size: 50px;
    line-height: calc(52 / 40);
    letter-spacing: 1px;
    @include sp {
      font-size: 40px;
    }
  }
}

.problem-container {
  @include pc_tab {
    max-width: 966px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 0;
  }
  @include sp {
    width: 100%;
  }
}



.example_obj {
  &_ttl {
    margin: 0 auto 25px;
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    @include sp {
      font-size: 22px;
    }
  }
  &_list {
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: 40px auto 0;
    @include sp {
      display: block;
      gap: 0;
      width: 100%;
    }
  }
  &_list_pc {
    display: flex;
    @include sp {
      display: none;
    }
  }
  &_list_sp {
    display: none;
    width: calc(100% + 40px);
    margin: 2px auto 0 -20px;
    @include sp {
      display: block;
    }
    .prev,
    .next {
      @include sp {
        position: absolute;
        z-index: 1;
        top: 125px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 47px;
        height: 47px;
        border-radius: 47px;
        background: $white;
      }
      &:before {
        @include sp {
          content: url(#{$img_dir}/common/sp/arrow-2.svg);
          margin: 2px 0 0 3px;
        }
      }
    }
    .prev {
      @include sp {
        left: 4px;
        transform: rotate(180deg);
      }
    }
    .next {
      @include sp {
        right: 4px;
      }
    }
    .slick-slide {
      @include sp {
        margin: 0 15px !important;
      }
    }
    .slick-dots {
      @include sp {
        display: flex;
        justify-content: center;
        margin: 20px auto 0;
      }
      button {
        @include sp {
          margin: 0 5px;
          width: 10px;
          height: 10px;
          background: $color_5;
          border: solid 1px $color_5;
          border-radius: 10px;
          color: transparent;
        }
      }
      .slick-active {
        button {
          @include sp {
            background: $white;
          }
        }
      }
    }
  }
  &_item {
    width: 336px;
    @include sp {
      width: 100%;
    }
  }
  &_img {
    border-radius: 5px;
    overflow: hidden;
    @include sp {
      margin: 0 auto;
      width: 295px;
      height: 195px;
    }
    .img {
      @include sp {
        width: 100%;
      }
    }
  }
  &_contents_01 {
    position: relative;
    margin: -30px auto 0;
    padding: 15px 0 18px;
    width: 306px;
    background: #fff;
    border-radius: 5px;
    @include sp {
      width: 265px;
    }
    .before_reduction {
      text-align: center;
      &_big {
        font-size: 20px;
        font-weight: 700;
        @include sp {
          font-size: 18px;
        }
        &:first-of-type {
          padding: 0 8px 0 0;
          @include sp {
            padding: 0 6px 0 0;
          }
        }
      }
      &_small {
        font-weight: 700;
        font-size: 14px;
        @include sp {
          font-size: 12px;
        }
      }
      &_num {
        color: $primary_color;
        font-size: 24px;
        letter-spacing: 1px;
        @include sp {
          display: inline-block;
          transform: translate(0px, 1px);
          font-weight: 600;
          font-size: 22px;
        }
        &.reduction_num_first {
          padding: 0 3px 0 0;
          .sm {
            font-size: 18px;
            @include sp {
              font-size: 16px;
            }
          }
        }
      }
    }
    .after_reduction {
      text-align: center;
      &_big {
        font-size: 24px;
        font-weight: 700;
        @include sp {
          font-size: 22px;
        }
        &:first-child {
          padding: 0 6px 0 0;
        }
      }
      &_small {
        font-size: 18px;
        font-weight: 700;
        @include sp {
          font-size: 16px;
        }
      }
      &_num {
        color: #EB5100;
        font-size: 28px;
        // letter-spacing: 1px;
        @include sp {
          display: inline-block;
          transform: translate(0px, 1px);
          font-weight: 600;
          font-size: 26px;
        }
        &.reduction_num_first {
          padding: 0 3px 0 0;
          .sm {
            font-size: 22px;
            @include sp {
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  &_contents_02{
    margin: 0 auto;
    padding: 10px 28px 23px;
    width: 306px;
    background: $primary_color;
    border-radius: 5px;
    @include sp {
      padding: 13px 15px 23px;
      width: 265px;
    }
  }
  &_txt {
    position: relative;
    margin: 0 auto;
    padding: 0 0 3px;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 16px;
    line-height: calc(30 / 16);
    font-weight: 700;
    @include sp {
      padding: 0 0 2px;
    }
    &::before {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 2px;
      content: "";
      background: #fff;
    }
    &::before {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      height: 2px;
      background: #fff;
    }
  }
  &_price {
    padding: 14px 0 0;
    text-align: center;
    color: #fff;
    font-size: 30px;
    font-weight: 700;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    @include sp {
      padding: 10px 0 0;
    }
    .price_num {
      color: $secondary_color;
      font-size: 40px;
      font-weight: 700;
      line-height: 1;
      @include sp {
        white-space: nowrap;
      }
      .sm {
        padding: 0 0 0 2px;
        font-size: 25px;
      }
    }
    .text_1 {
      margin: 0 5px 0 0;
    }
    .text_2 {
      margin: 0 0 0 5px;
    }
  }
  &_year {
    padding: 0 2px 0 0;
    writing-mode: vertical-rl;
    color: #fff;
    font-weight: 700;
    font-size: 16px;
  }
}

.example_txt_03 {
  padding: 30px 0 0;
  margin: 0 auto 20px;
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  @include sp {
    margin: 35px auto 10px;
    padding: 0;
    font-size: 19px;
  }
  .red_text {
    background: none;
    font-size: 34px;
    line-height: calc(46 / 28);
    @include sp {
      font-size: 28px;
    }
  }
}

.example_effect {
  &_annotation {
    margin: 20px 0 0 885px;
    width: fit-content;
    font-size: 13px;
    @include sp {
      margin: 20px auto 0;
      font-size: 12px;
      line-height: calc(20 / 13);
    }
    &:before {
      content: '※';
      flex-shrink: 0;
    }
  }
  &_list {
    display: flex;
    justify-content: space-between;
    @include sp {
      flex-direction: column;
      justify-content: inherit;
      gap: 33px;
      padding: 0 10px;
    }
  }
  &_item {
    width: 336px;
    @include sp {
      width: auto;
    }
  }
  &_head {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 27px;
    @include sp {
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      gap: 15px;
    }
  }
  &_img {
    width: 100%;
    @include sp {
      width: fit-content;
    }
    .img {
      width: 336px;
      @include sp {
        width: 190px;
      }
    }
  }
  &_ttl {
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    color: $color_5;
  }
  &_text {
    margin: 13px auto 0;
    text-align: center;
    font-size: 16px;
    line-height: calc(30 / 16);
    @include sp {
      text-align: left;
    }
  }
}

.sec_under_arrow_ver02 {
  margin: 8px auto 2px;
  width: 18px;
  height: 22px;
}



.example-container {
  @include sp {
    padding: 0 20px;
  }
  @include pc_tab {
    max-width: 1116px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 0 110px;
  }
}

.reason {
  background: $blue_1;
  @include sp {
    padding: 0 0 40px;
  }
  .ttl01 {
    @include sp {
      margin: 0 0 20px;
    }
  }
  &_list {
    display: flex;
    flex-direction: column;
    gap: 50px;
    margin: 40px 0 0;
    @include sp {
      margin: 10px 0 0;
      gap: 30px;
    }
  }
  &_item {
    display: flex;
    align-items: center;
    gap: 30px;
    width: 100%;
    background: #FFFFFF;
    @include sp {
      flex-direction: column;
      gap: 20px;
      padding: 0 0 30px;
    }
  }
  &_item:nth-of-type(odd) {
    @include sp {
      flex-direction: column-reverse;
    }
  }
  &_img {
    width: 452px;
    height: 301px;
    @include sp {
      width: 100%;
      height: fit-content;
    }
    .img {
      @include sp {
        max-width: 100%;
        padding: 0 15px;
      }
    }
  }
  &_contents_ver01 {
    position: relative;
    padding: 0 40px 0 0;
    @include sp {
      padding: 37px 0 0 0;
    }
    &::before {
      position: absolute;
      content: "";
      z-index: 0;
    }
  }
  &_contents_ver02 {
    position: relative;
    padding: 0 0 0 40px;
    @include sp {
      padding: 37px 0 0 0;
    }
    &::before {
      position: absolute;
      content: "";
      z-index: 0;
    }
  }
  &_item:first-of-type {
    .reason_contents {
      &::before {
        top: -52px;
        right: 30px;
        width: 70px;
        height: 84px;
        background-image: url(#{$img_dir}/pc_path_01.png);
        @include sp {
          top: 15px;
          right: 17px;
          width: 47px;
          height: 56px;
          background-image: url(#{$img_dir}/sp_path_01.png);
        }
      }
    }
  }
  &_item:nth-of-type(2) {
    .reason_contents{
      &::before {
        top: -67px;
        right: 0;
        width: 87px;
        height: 84px;
        background-image: url(#{$img_dir}/pc_path_02.png);
        @include sp {
          top: 15px;
          right: 17px;
          width: 58px;
          height: 56px;
          background-image: url(#{$img_dir}/sp_path_02.png);
        }
      }
    }
  }
  &_item:nth-of-type(3) {
    .reason_contents{
      &::before {
        top: -9px;
        right: 22px;
        width: 87px;
        height: 84px;
        background-image: url(#{$img_dir}/pc_path_03.png);
        @include sp {
          top: 15px;
          right: 17px;
          width: 58px;
          height: 56px;
          background-image: url(#{$img_dir}/sp_path_03.png);
        }
      }
    }
    .reason_lead_highlight {
      @include sp {
        padding: 0;
      }
    }
  }
  &_item:nth-of-type(4) {
    .reason_contents{
      &::before {
        top: -9px;
        right: 0;
        width: 87px;
        height: 84px;
        background-image: url(#{$img_dir}/pc_path_04.png);
        @include sp {
          top: 15px;
          right: 17px;
          width: 58px;
          height: 56px;
          background-image: url(#{$img_dir}/sp_path_04.png);
        }
      }
    }
  }
  &_item:nth-of-type(5) {
    .reason_contents{
      &::before {
        top: -21px;
        right: 30px;
        width: 87px;
        height: 84px;
        background-image: url(#{$img_dir}/pc_path_05.png);
        @include sp {
          top: 15px;
          right: 17px;
          width: 58px;
          height: 56px;
          background-image: url(#{$img_dir}/sp_path_05.png);
        }
      }
    }
    .reason_lead_highlight {
      @include sp {
        padding: 0;
      }
    }
  }
  &_lead{
    position: relative;
    padding: 0 0 20px 0;
    font-size: 24px;
    font-weight: 700;
    z-index: 2;
    @include sp {
      padding: 0 0 20px 15px;
      font-size: 20px;
      line-height: calc(40 / 28);
    }
    &_highlight {
      padding: 0 0 0 2px;
      font-size: 32px;
      color: $primary_color;
      @include sp {
        font-size: 28px;
      }
    }
  }
  &_text{
    position: relative;
    z-index: 2;
    @include sp {
      padding: 0 15px;
    }
    .u_txt_13-1 {
      position: relative;
      display: block;
      margin: 20px 0;
      padding: 0 0 0 1em;
      @include sp {
        margin: 20px 0 0;
      }
      &::before {
        position: absolute;
        top: 0;
        left: 0;
        content: "※";
        font-size: 13px;
      }
    }
  }
  &_sub_lead {
    position: relative;
    margin: 25px 0 10px;
    padding: 0 0 0 17px;
    font-size: 18px;
    font-weight: 700;
    @include sp {
      padding: 0 15px 0 37px;
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 0;
      content: "";
      width: 12px;
      height: 2px;
      background: #0682E0;
      transform: translate(0, -50%);
      @include sp {
        left: 15px;
      }
    }
  }
  &_sub_text {
    font-size: 14px;
    line-height: calc(24 / 14);
    @include sp {
      padding: 0 15px;
    }
  }
}
.reason-container {
  padding: 40px 15px 0;
  @include pc_tab {
    max-width: 1086px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 0;
  }
}

.flow {
  &_list {
    display: flex;
    flex-direction: column;
    margin: 40px 0;
    width: 100%;
    @include sp {
      margin: 0 0 20px;
      padding: 0 15px;
    }
  }
  &_item {
    counter-increment: num;
    position: relative;
    margin: 0 0 82px;
    padding: 40px 40px 40px 27px;
    border-radius: 5px;
    @include sp {
      width: 100%;
      margin: 0 0 56px;
      padding: 16px 15px 20px 15px;
    }
    &:last-of-type {
      margin: 0;
    }
    &:not(:last-of-type) {
      &::before {
        position: absolute;
        bottom: -40px;
        left: 50%;
        transform: translate(-50%, 50%);
        width: 40px;
        height: 22px;
        content: "";
        @include sp {
          bottom: -30px;
        }
      }
    }
    &:first-of-type {
      &::before {
        background: url(#{$img_dir}/pc_arrow_01.svg) no-repeat;
        @include sp {
          background: url(#{$img_dir}/sp_arrow_01.svg) no-repeat;
        }
      }
    }
    &:nth-of-type(2) {
      &::before {
        background: url(#{$img_dir}/pc_arrow_02.svg) no-repeat;
        @include sp {
          background: url(#{$img_dir}/sp_arrow_02.svg) no-repeat;
        }
      }
    }
    &:nth-of-type(3) {
      &::before {
        background: url(#{$img_dir}/pc_arrow_03.svg) no-repeat;
        @include sp {
          background: url(#{$img_dir}/sp_arrow_03.svg) no-repeat;
        }
      }
    }
  }
  &_item:nth-of-type(1) {
    background: #6AADE0;
  }
  &_item:nth-of-type(2) {
    background: #4498D8;
  }
  &_item:nth-of-type(3) {
    background: #2C8CD4;
  }
  &_item:nth-of-type(4) {
    background: #0777CC;
  }
  &_item_inner{
    display: flex;
    align-items: center;
    gap: 27px;
    @include sp {
      flex-direction: column;
      align-items: inherit;
      justify-content: center;
      gap: 7px;
    }
  }
  &_contents {
    display: flex;
    flex-direction: column;
    margin: 5px 0 0;
    @include sp {
      margin: inherit;
    }
  }
  &_ttl {
    position: relative;
    padding: 0 0 4px;
    width: fit-content;
    font-size: 24px;
    font-weight: 700;
    color: #FFFFFF;
    border-bottom: 1px solid #fff;
    @include sp {
      margin: 0 auto;
      font-size: 20px;
      text-align: center;
    }
  }
  &_text{
    padding: 13px 0 0;
    font-size: 16px;
    line-height: calc(30 / 16);
    color: #FFFFFF;
  }
  &_arrow {
    width: fit-content;
    margin: 30px auto;
    @include sp {
      margin: 20px auto;
    }
    .arrow_img {
      @include sp {
        width: 32px;
      }
    }
  }
}
.step {
  &_wrapper {
    display: flex;
    flex-direction: column;
    @include sp {
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }
  &_word {
    margin: 0px auto -13px;
    text-align: center;
    color: #FFFFFF;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    font-family: "solano-gothic-pro-mvb", sans-serif;
    text-shadow: 0px 2px 3px $shadow_1;
    @include sp {
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
    }
  }
  &_num {
    color: #FFFFFF;
    font-size: 59px;
    font-style: normal;
    font-weight: 700;
    font-family: "solano-gothic-pro-mvb", sans-serif;
    text-shadow: 0px 2px 3px $shadow_1;
    @include sp {
      font-size: 46px;
      position: relative;
      top: -3px;
      margin: 0 0 0 3px;
    }
    &::before {
      content: counter(num, decimal-leading-zero);
    }
  }
}

.flow-container {
  @include pc_tab {
    max-width: 966px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 0;
  }
}

.faq {
}

.faq-container {
  @include pc_tab {
    max-width: 966px;
    width: 100%;
    margin: 0 auto;
    padding: 80px 0;
  }
  .wrapper {
    padding: 0;
    @include sp {
      padding: 0 0 40px;
    }
  }
}

.bnr {
  &_contact {
    margin: 0 auto;
    width: 100%;
    height: 227px;
    background: url(#{$img_dir}/pc_img_cv-1.jpg) no-repeat;
    background-size: cover;
    @include sp {
      margin: 56px 0 0;
      width: 100vw;
      height: 200px;
      background: url(#{$img_dir}/sp_bg.png) no-repeat;
      background-size: cover;
    }
    .bnr_contact_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 0 1px;
      width: 440px;
      height: 80px;
      @include sp {
        width: 345px;
        height: 57px;
      }
    }
    .bnr_btn_text_02 {
      @include sp {
        font-size: 22px;
      }
      &::before {
        right: -37px;
        @include sp {
          top: 20px;
          right: 25px;
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  &_contact_inner {
    display: flex;
    justify-content: space-between;
    width: 981px;
    margin: 0 auto;
    @include sp {
      position: relative;
      width: 345px;
    }
  }
  &_img {
    &.img_lady {
      .img {
        width: 195px;
        @include sp {
          width: 114px;
        }
      }
      margin-top: -30px;
      @include sp {
        position: absolute;
        top: 30px;
        left: -9px;
        width: 114px;
      }
    }
    &.img_breaker {
      margin-top: -20px;
      .img {
        width: 224px;
        @include sp {
          width: auto;
        }
      }
    }
  }
  &_contact_contents {
    @include sp {
      margin: 0 auto;
      width: 100%;
    }
  }
  &_text {
    margin: 41px auto 28px;
    color: #fff;
    font-size: 32px;
    text-shadow: 0px 2px 3px rgba(0, 0, 0, 0.46);
    @include sp {
      margin: 26px 19px 30px 0;
      text-align: right;
      font-size: 27px;
      line-height: calc(36 / 27);
    }

  }
  &_contact_btn {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    width: 460px;
    height: 100px;
    background: transparent linear-gradient(180deg, #FFF0A0 10%, #F28736 35%, #EB5100 63%, #F26D0E 69%, #FFA62C 83%) 0% 0% no-repeat padding-box;
    background-size: 100% 180%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 140px;
    box-shadow: 0px 4px 0px 0px #A23800;
    text-align: center;
    transition: all .3s;
    margin: 0 auto;
    @include sp {
      margin: -10px 0 0;
      width: 345px;
    }
    &:hover {
      @include pc_tab {
        top: 2px;
        box-shadow: none;
      }
    }
  }
  &_btn_text_01 {
    
  }
  &_btn_text_02 {
    text-align: center;
    font-size: 30px;
    color: #fff;
    font-weight: 700;
    display: block;
    letter-spacing: 0.6px;
    @include sp {
      font-size: 17px;
    }
    @include pc_tab {
      transition: all .3s;
      position: relative;
      top: 0;
    }
    &::before {
      position: absolute;
      top: 6px;
      right: -37px;
      content: "";
      border-bottom: solid 3px #fff;
      border-right: solid 3px #fff;
      width: 18px;
      height: 18px;
      transform: rotate(45deg);
      @include sp {
        right: 0;
      }
    }
  }
}

.wrapper {
  max-width: 1366px;
  margin: 0 auto;
  padding: 40px 0;
}

// faq
.faq {
  border-top: 2px dotted $black_3;
  border-spacing: 3px;
  &:last-child {
    border-bottom: 2px dotted $black_3;
  }
  &_inner {
    @include sp {
      width: 345px;
      margin: 0 auto;
    }
  }
}
.faq-ttl {
  position: relative;
  margin: 0 0 0 10px;
  padding: 30px 0 30px 20px;
  font-size: 18px;
  font-weight: 700;
  line-height: calc(30 / 18);
  cursor: pointer;
  @include sp {
    padding: 15px 0 15px 20px;
    width: 325px;
  }
  &::before {
    content: 'Q.';
    position: absolute;
    top: 50%;
    left: 0;
    font-family: "solano-gothic-pro-mvb", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    color: $primary_color;
    transform: translate(-50%,-50%);
    @include sp {
      top: 27px;
    }
  }
  &:after {
    content: '';
    background: no-repeat url(#{$img_dir}/under_arrow.svg);
    width: 17px;
    height: 10px;
    position: absolute;
    top: 50%;
    right: 10px;
    display: inline-block;
    transform: translate(0, -50%);
    transition: transform .3s;
    @include sp {
      right: -10px;
    }
  }
  &.is_open {
    &:after {
      transform: scale(1, -1);
    }
  }
}
.faq-dtl {
  position: relative;
  display: none;
  width: 914px;
  padding: 0 0 33px 30px;
  font-size: 16px;
  line-height: calc(30 / 16);
  @include sp {
    width: 100%;
  }
  &::before {
    content: 'A.';
    position: absolute;
    top: 0;
    left: 0;
    font-family: "solano-gothic-pro-mvb", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 36px;
    color: #EB5100;
    transform: translate(0, -24%);
  }
}

.faq-container {
  max-width: 966px;
  width: 100%;
  margin: 0 auto;
  padding: 80px 0;
  @include sp {
    padding: 0;
  }
}

.form {
  background: $blue_1;
}

.form-container {
  @include pc_tab {
    max-width: 966px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 0;
  }
  @include sp {
    padding: 20px 15px;
  }
}

.form-box {
  background: $white;
  @include pc_tab {
    padding: 45px 180px 40px;
  }
  @include sp {
    padding: 20px 15px;
    border-radius: 5px;
  }
  .ttl-sub {
    color: $blue_3;
    @include pc_tab {
      @include fz(20, 30);
    }
    @include sp {
      @include fz(18, 27);
    }
  }
  .ttl01 {
    margin: 7px 0 40px;
    @include sp {
      margin: 2px 0 0;
    }
  }
  .ttl-sub_obj {
    padding: 0 20px 0 25px;
    position: relative;
    display: inline-block;
    @include sp {
      padding: 0 10px 0 14px;
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 5px;
      display: inline-block;
      width: 21px;
      height: 22px;
      @include sp {
        top: 7px;
        width: 14px;
        height: 15px;
      }
    }
    &:before {
      left: 0;
      background: url(#{$img_dir}/obj01-left.svg) no-repeat center center / contain;
    }
    &:after {
      right: 0;
      background: url(#{$img_dir}/obj01-right.svg) no-repeat center center / contain;
    }
  }
}

.form-table {
  .form-row {
    @include pc_tab {
      padding: 20px 0;
    }
    @include sp {
      padding: 15px 0;
    }
    &:nth-of-type(2) {
      .form-ttl_m  {
        &::after {
          right: -101px;
        }
      }
    }
  }
  .form-ttl_m {
    position: relative;
    &:after {
      @include fz(14, 22, 0.42);
      // margin-left: 10px;
      position: absolute;
      top: 50%;
      right: -10px;
      padding: 0 4px 2px;
      color: $white;
      border-radius: 2px;
      transform: translate(100%, -50%);
    }
    &._require {
      &:after {
        content: '必須';
        background: $red_1;
      }
    }
    &:not(._require) {
      &:after {
        content: '任意';
        background: $blue_3;
      }
    }
  }
  .form-item {
    position: relative;
    @include pc_tab {
      margin-top: 20px;
    }
    @include sp {
      margin-top: 10px;
    }
    &._tel,
    &._mail {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        display: inline-block;
      }
      &.icon_none {
        &:before {
          display: none;
        }
      }
      .form-input {
        &::placeholder {
          padding-left: 20px;
        }
      }
    }
    &._tel {
      @include sp {
        width: 65%;
      }
      &:before {
        top: 18px;
        left: 20px;
        width: 15px;
        height: 19px;
        background: url(#{$img_dir}/icon-tel.svg) no-repeat top center / contain;
      }
    }
    &._mail {
      &:before {
        top: 21px;
        left: 23px;
        width: 16px;
        height: 12px;
        background: url(#{$img_dir}/icon-mail.svg) no-repeat top center / contain;
      }
    }
    &._select {
      position: relative;
      display: inline-block;
      &:after {
        content: '▼';
        position: absolute;
        top: 14px;
        right: 15px;
        display: inline-block;
        font-size: 1.2rem;
      }
      .form-input {
        padding-right: 50px;
      }
    }
  }
  .form-input {
    width: 100%;
    padding: 10px 20px;
    border: 1px solid $black_3;
    border-radius: 5px;
    @include sp {
      padding: 10px 20px;
    }
    &::placeholder {
      color: $black_3;
    }
    &._sm {
      @include pc_tab {
        width: 33%;
      }
    }
    &._md {
      @include pc_tab {
        width: 58%;
      }
    }
    &._lg {
      @include pc_tab {
        width: 83%;
      }
    }
  }
  textarea.form-input {
    @include pc_tab {
      height: 250px;
    }
  }
}

.form-privacy {
  margin-top: 20px;
  @include sp {
    margin-top: 15px;
  }
  & + label.error {
    text-align: center;
    width: 100%;
  }
  input.error {
    display: inline-block;
  }
  .form-txt {
  }
  .form-link {
    @include a_tag(#0069B8, #0069B8);
    // border-bottom: 1px solid #0069B8;
    // transition: opacity .3s;
    position: relative;
    transition: all .3s;
    &::before {
      position: absolute;
      top: 100%;
      left: 0;
      width: calc(100% + 20px);
      height: 1px;
      content: "";
      background: #0069B8;
      transition: all .3s;
      @include sp {
        display: none;
      }
    }
    &:hover {
      opacity: .5;
      transition: all .3s;
      &::before {
        content: "";
        background: none;
        transition: all .3s;
      }
    }
    @include pc {
      &:hover {
        border-bottom: none;
        opacity: .6;
      }
    }
    &[target="_blank"] {
      position: relative;
      margin-right: 20px;
      @include sp {
        margin-right: 3px;
      }
      @include sp {
        padding: 0 18px 0 0;
        border-bottom: 1px solid #0069B8;
      }
      &:after {
        content: '';
        position: absolute;
        top: 6px;
        right: -16px;
        display: inline-block;
        width: 13px;
        height: 13px;
        background: url(#{$img_dir}/icon-blank-blue.svg) no-repeat top center / contain;
        @include mq(431, 768) {
          top: 6px;
        }
        @include mq_max(430) {
          top: 36px;
          right: 1px;
        }
      }
    }
  }
  .form-item {
    margin-top: 20px;
    input[type="checkbox"] {
      width: 18px;
      height: 18px;
      margin: 0 10px 5px 0;
      border-radius: 2px;
      border: solid 1px #707070;
      appearance: none;
      cursor: pointer;
    }
    input:checked[type="checkbox"] {
      background: url(#{$img_dir}/icon-check.svg) no-repeat center center;
      background-size: 14px 14px;
    }
  }
  .mwform-checkbox-field-text {
    @include fz(16, 30);
  }
}

.form-submit {
  .form-item {
    margin-top: 30px;
  }
  .form-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    max-width: 305px;
    height: 50px;
    margin: 0 auto;
    border-radius: 9999px;
    background: $blue_3;
    font-weight: bold;
    color: $white;
    font-size: 2.6rem;
    transition: 0.3s;
    cursor: pointer;
    @include pc {
      &:hover {
        opacity: .6;
        &:after {
          right: 20px;
        }
      }
    }
    @include sp {
      height: 57px;
    }
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      right: 25px;
      display: inline-block;
      width: 9px;
      height: 9px;
      border-top: 2px solid $white;
      border-right: 2px solid $white;
      transform: translate(0, -50%) rotate(45deg);
      transition: 0.3s;
    }
    input {
      width: 100%;
      height: 100%;
      border-radius: 9999px;
    }
  }
}

/* ======================================================================
 p-contact_
====================================================================== */
/**
 * error message
 */
.mw_wp_form label.error {
  @include fz(14, 28);
  color : #B70000;
  display : block;
}

/**
 * error message of akismet
 */
.mw_wp_form .akismet_error {
  display: block;
}

/**
 * vertical style of radio and checkbox
 */
.mw_wp_form .vertical-item {
  display: block;
}
.mw_wp_form .vertical-item + .vertical-item {
  margin-top: 5px;
}

/**
 * horizontal style of radio and checkbox
 */
.mw_wp_form .horizontal-item + .horizontal-item {
  margin-left: 10px;
}

/**
 * radio and checkbox
 */
.mwform-checkbox-field label,
.mwform-radio-field label {
  font-weight: normal;
  margin-bottom: 0;
}
.mwform-checkbox-field input,
.mwform-radio-field input {
  margin-right: 5px;
}

/**
 * datepicker
 */
.mw_wp_form .ui-datepicker .ui-datepicker-title select.ui-datepicker-month,
.mw_wp_form .ui-datepicker .ui-datepicker-title select.ui-datepicker-year {
  width: 40%;
  margin: 0 5px;
}

/**
 * deprecated message
 */
.mw-wp-form-deprecated-message {
  background: #F5A9A9;
  border: #B40404 solid 2px;
  border-radius: 5px;
  font-size: 10px;
  line-height: 1.6;
  margin: 0 0 5px;
  padding: 5px;
}

/**
 * file
 */
.mw_wp_form input[type="file"] {
  display: inline-block;
}

/**
 * file delete button
 */
.mwform-file-delete {
  cursor: pointer;
  visibility: hidden;
}

/**
 * tel and zip
 */
.mwform-tel-field input[type="text"],
.mwform-zip-field input[type="text"] {
  display: inline-block;
  width: auto;
}

.mw_wp_form label.error {
  display: inline-block !important;
}

