//======================================================================
// variable
//======================================================================
// imgディレクトリまでのパス
$img_dir: '../img';

// コンテンツ幅
$content_width: 1086px;

// SP時の左右の余白（片方）
$sp_side_padding: 15px;

//=================================
// breakpoint
//=================================
// 下記2つの変数の間がタブレット表示
// タブレット表示なしの場合は2つの変数の値を「1px差」で設定すること

// $pc_bp以上がPC表示
$pc_bp: 1025px;

// $sp_bp以下がSP表示
$sp_bp: 767px;

//=================================
// font
//=================================
$font_1: 'Noto Sans JP', 'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', '游ゴシック', YuGothic, sans-serif;

//=================================
// color
//=================================
$black_1: #000;
$black_2: #222;
$black_3: #8B8B8B;

$white: #fff;


$color_1: #222;
$color_2: #ffe200;
$color_3: #c9aa00;
$color_4: #0000001a;
$color_5: #0069b8;
$color_6: #22222280;


$blue_1: #F2F8FC;
$blue_2: #0069B8;
$blue_3: #0777CC;

$red_1: #EB5100;

$primary_color: #0777CC;
$secondary_color: #FFE200;

$shadow_1: #04345833;

//=================================
// CSS Transition Easing
//=================================
// Default
$linear: cubic-bezier(0.250, 0.250, 0.750, 0.750);
$ease: cubic-bezier(0.250, 0.100, 0.250, 1.000);
$ease-in: cubic-bezier(0.420, 0.000, 1.000, 1.000);
$ease-out: cubic-bezier(0.000, 0.000, 0.580, 1.000);
$ease-in-out: cubic-bezier(0.420, 0.000, 0.580, 1.000);
// In
$easeInQuad: cubic-bezier(0.550, 0.085, 0.680, 0.530);
$easeInCubic: cubic-bezier(0.550, 0.055, 0.675, 0.190);
$easeInQuart: cubic-bezier(0.895, 0.030, 0.685, 0.220);
$easeInQuint: cubic-bezier(0.755, 0.050, 0.855, 0.060);
$easeInSine: cubic-bezier(0.470, 0.000, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.950, 0.050, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.600, 0.040, 0.980, 0.335);
$easeInBack: cubic-bezier(0.600, -0.280, 0.735, 0.045);
// Out
$easeOutQuad: cubic-bezier(0.250, 0.460, 0.450, 0.940);
$easeOutCubic: cubic-bezier(0.215, 0.610, 0.355, 1.000);
$easeOutQuart: cubic-bezier(0.165, 0.840, 0.440, 1.000);
$easeOutQuint: cubic-bezier(0.230, 1.000, 0.320, 1.000);
$easeOutSine: cubic-bezier(0.390, 0.575, 0.565, 1.000);
$easeOutExpo: cubic-bezier(0.190, 1.000, 0.220, 1.000);
$easeOutCirc: cubic-bezier(0.075, 0.820, 0.165, 1.000);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.320, 1.275);
// In Out
$easeInOutQuad: cubic-bezier(0.455, 0.030, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1.000);
$easeInOutQuart: cubic-bezier(0.770, 0.000, 0.175, 1.000);
$easeInOutQuint: cubic-bezier(0.860, 0.000, 0.070, 1.000);
$easeInOutSine: cubic-bezier(0.445, 0.050, 0.550, 0.950);
$easeInOutExpo: cubic-bezier(1.000, 0.000, 0.000, 1.000);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.150, 0.860);
$easeInOutBack: cubic-bezier(0.680, -0.550, 0.265, 1.550);


//======================================================================
// mixin
//======================================================================

//=================================
// clearfix
//=================================
@mixin cf {
  &:after {
    display: block;
    clear: both;
    content: "";
  }
}

//=================================
// safe-area
//=================================
@mixin safe_area {
  padding-top: constant(safe-area-inset-top);
  padding-bottom: constant(safe-area-inset-bottom);
  padding-left: constant(safe-area-inset-left);
  padding-right: constant(safe-area-inset-right);
  padding-top: env(safe-area-inset-top);
  padding-bottom: env(safe-area-inset-bottom);
  padding-left: env(safe-area-inset-left);
  padding-right: env(safe-area-inset-right);
}

//=================================
// aタグのカラー変更
//=================================
@mixin a_tag($color1, $color2) {
  &:link,
  &:active,
  &:visited {
    color: $color1;
    cursor: pointer;
  }
  &:hover {
    color: $color2;
  }
}

//=================================
// プレースホルダーのカラー変更
//=================================
@mixin placeholder($color) {
  &:placeholder-shown {
    color: $color;
  }
  &::-webkit-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &::-moz-placeholder {
    color: $color;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
}

//=================================
// media query
//=================================
@mixin mq($min_width, $max_width) {
  @media screen and (min-width: $min_width + px) and (max-width: $max_width + px) {
    @content;
  }
}
@mixin mq_min($width) {
  @media screen and (min-width: $width + px) {
    @content;
  }
}
@mixin mq_max($width) {
  @media screen and (max-width: $width + px) {
    @content;
  }
}

// device
@mixin pc {
  @media screen and (min-width: $pc_bp) {
    @content;
  }
}
@mixin sp {
  @media screen and (max-width: $sp_bp) {
    @content;
  }
}
@mixin tab {
  @media screen and (min-width: $sp_bp + 1px) and (max-width: $pc_bp - 1px) {
    @content;
  }
}
@mixin pc_tab{
  @media screen and (min-width: $sp_bp + 1px) {
    @content;
  }
}
@mixin sp_tab {
  @media screen and (max-width: $pc_bp - 1px) {
    @content;
  }
}
@mixin pc_content {
  @media screen and (min-width: $pc_bp) and (max-width: $content_width) {
    @content;
  }
}

//=================================
// css hack
//=================================
// Chrome(and Safari, Opera)
@mixin chrome {
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @content;
  }
}
// FireFox
@mixin firefox {
  _:lang(x)::-moz-placeholder, & {
    @content;
  }
}
// Edge
@mixin edge {
  _:lang(x)::-ms-, & {
    @content;
  }
}
// IE(11)
@mixin ie {
  _:-ms-lang(x)::-ms-backdrop, & {
    @content;
  }
}
// Safari
@mixin safari {
  _:lang(x)+_:-webkit-full-screen-document, & {
    @content;
  }
}

//=================================
// object fit images
//=================================
@mixin ofi($size) {
  object-fit: $size;
  @include ie {
    font-family: 'object-fit: #{$size};'
  }
}

//=================================
// hide scrollbar
//=================================
@mixin hide_scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display:none;
  }
}

//=================================
// font size
//=================================
@mixin fz($size, $height, $spacing: false) {
  font-size: $size * .1rem;
  line-height: calc($height / $size);
  @if $spacing {
    letter-spacing: $spacing + px;
  }
}

//=================================
// header
//=================================
.nav {
  .item {
    transition: all .3s;
    &:hover {
      opacity: .5;
      transition: all .3s;
    }
  }
}

.h2_sub_ttl {
  position: relative;
  display: block;
  margin: 0 auto;
  padding: 0 25px 10px;
  width: fit-content;
  text-align: center;
  font-size: 20px;
  color: $primary_color;
  @include sp {
    font-size: 18px;
  }
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 22px;
    height: 22px;
    content: "";
    background: no-repeat url(#{$img_dir}/sub_ttl_obj.svg);
  }
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 22px;
    height: 22px;
    content: "";
    background: no-repeat url(#{$img_dir}/sub_ttl_obj_02.svg);
  }
}